
import React from "react";
  
const About = (handleChange) => {
  
  return (
    <div className="mx-5 pt-5 ">
      {/* <div className="text-xl ">
      About me
      </div> */}
      <div className="text-sm">
        <ul>
          <li>I'm Co-founder and CTO of Prophetic AI. I live in New York City. In my free time, I like to prototype technologies, listen to music, paint, play guitar and piano, and enjoy nature.</li>
        </ul>
      </div>
    </div>
  );
};
  
export default About;