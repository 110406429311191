
import React from "react";
  
const Projects = (handleChange) => {
  return (
    <div className=" px-4 pt-5">
      <div className=" text-2xl ">
      Projects
      </div>
      <div className="text-sm">
        (I'm working on this)
      </div>
    </div>
  );
};
  
export default Projects;